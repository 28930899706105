import React from 'react';
import SEO from '../components/SEO';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <div>
    <SEO title="Page Not Found" />
    <h5>
      There is nothing here.
      <Link to="/">Take me Home</Link>
    </h5>
  </div>
);

export default NotFoundPage;
